var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viewDashBoard project-route-index"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"beforeLeave":_vm.beforeLeave,"enter":_vm.enter,"afterEnter":_vm.afterEnter}},[(_vm.loader)?_c('div',{key:"1",staticClass:"white-row loader-route"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_c('div',{key:"2",staticClass:"white-row"},[_c('div',{staticClass:"show-project-portal-listing"},[_c('div',{staticClass:"form-area"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row top-gen-heading"},[_c('h1',{staticClass:"global-style-heading"},[_vm._v(" Create Projects ")])])]),_c('div',{staticClass:"col-sm-12"},[_c('form',{staticClass:"project-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Project Name: "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.project_name),expression:"formData.project_name"}],staticClass:"form-control",class:{
                              'input-border-errors':
                                _vm.$v.formData.project_name.$dirty &&
                                !_vm.$v.formData.project_name.required
                            },attrs:{"type":"text","placeholder":"Project Name"},domProps:{"value":(_vm.formData.project_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "project_name", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v("Select Brands"),_c('span',[_vm._v("*")])]),_c('Select2',{class:{
                              'input-border-errors':
                                _vm.$v.formData.brand_id.$dirty &&
                                !_vm.$v.formData.brand_id.required
                            },attrs:{"options":_vm.brand_options,"settings":{ placeholder: 'Select Brands' }},on:{"change":_vm.getAmOfBrand},model:{value:(_vm.formData.brand_id),callback:function ($$v) {_vm.$set(_vm.formData, "brand_id", $$v)},expression:"formData.brand_id"}})],1)])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v("Select Account Manager "),_c('span',[_vm._v("*")])]),_c('Select2',{class:{
                              'input-border-errors':
                                _vm.$v.formData.am_id.$dirty &&
                                !_vm.$v.formData.am_id.required
                            },attrs:{"options":_vm.ams_options,"settings":{
                              placeholder: 'Select Account Manager',
                              multiple: true
                            }},on:{"change":_vm.getAmClients},model:{value:(_vm.formData.am_id),callback:function ($$v) {_vm.$set(_vm.formData, "am_id", $$v)},expression:"formData.am_id"}})],1)])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v("Project Client")]),_c('Select2',{class:{
                              'input-border-errors':
                                _vm.$v.formData.client_id.$dirty &&
                                !_vm.$v.formData.client_id.required
                            },attrs:{"options":_vm.client_options,"settings":{
                              placeholder: 'Project Client',
                              multiple: true
                            }},model:{value:(_vm.formData.client_id),callback:function ($$v) {_vm.$set(_vm.formData, "client_id", $$v)},expression:"formData.client_id"}})],1)])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v("Project Category")]),_c('Select2',{class:{
                              'input-border-errors':
                                _vm.$v.formData.category_id.$dirty &&
                                !_vm.$v.formData.category_id.required
                            },attrs:{"options":_vm.cat_options,"settings":{ placeholder: 'Project Category' }},model:{value:(_vm.formData.category_id),callback:function ($$v) {_vm.$set(_vm.formData, "category_id", $$v)},expression:"formData.category_id"}})],1)])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v("Select Project Status*")]),_c('Select2',{class:{
                              'input-border-errors':
                                _vm.$v.formData.project_status.$dirty &&
                                !_vm.$v.formData.project_status.required
                            },attrs:{"options":_vm.status_options,"settings":{
                              placeholder: 'Select Project Status*'
                            }},model:{value:(_vm.formData.project_status),callback:function ($$v) {_vm.$set(_vm.formData, "project_status", $$v)},expression:"formData.project_status"}})],1)])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v("Add In House Team Member")]),_c('Select2',{attrs:{"options":_vm.inhouse_team_options,"settings":{
                              placeholder: 'Add In House Team Member',
                              multiple: true
                            }},model:{value:(_vm.formData.in_house_team_member),callback:function ($$v) {_vm.$set(_vm.formData, "in_house_team_member", $$v)},expression:"formData.in_house_team_member"}})],1)])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Start Date: "),_c('span',[_vm._v("*")])]),_c('datepicker',{class:{
                              'input-border-errors':
                                _vm.$v.formData.start_date.$dirty &&
                                !_vm.$v.formData.start_date.required
                            },attrs:{"minimumView":'day',"maximumView":'month',"initialView":'month'},model:{value:(_vm.formData.start_date),callback:function ($$v) {_vm.$set(_vm.formData, "start_date", $$v)},expression:"formData.start_date"}},[_c('span',{staticClass:"animated-placeholder",attrs:{"slot":"afterDateInput"},slot:"afterDateInput"},[_c('i',{staticClass:"far fa-calendar-alt"})])])],1)])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("End Date: "),_c('span',[_vm._v("*")])]),_c('datepicker',{attrs:{"minimumView":'day',"maximumView":'month',"initialView":'month'},model:{value:(_vm.formData.end_date),callback:function ($$v) {_vm.$set(_vm.formData, "end_date", $$v)},expression:"formData.end_date"}},[_c('span',{staticClass:"animated-placeholder",attrs:{"slot":"afterDateInput"},slot:"afterDateInput"},[_c('i',{staticClass:"far fa-calendar-alt"})])])],1)])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label"},[_vm._v("Package details")]),_c('ckeditor',{class:{
                              'input-border-errors':
                                _vm.$v.formData.project_summary.$dirty &&
                                !_vm.$v.formData.project_summary.required
                            },attrs:{"config":_vm.editorConfig},model:{value:(_vm.formData.project_summary),callback:function ($$v) {_vm.$set(_vm.formData, "project_summary", $$v)},expression:"formData.project_summary"}})],1)])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Project Note: "),_c('span',[_vm._v("*")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.project_note),expression:"formData.project_note"}],staticClass:"form-control",attrs:{"placeholder":"Project Note"},domProps:{"value":(_vm.formData.project_note)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "project_note", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.disableSubmitBtn,"type":"submit","id":"save-form"}},[_vm._v(" Create ")]),(_vm.disableSubmitBtn)?_c('div',{staticClass:"form_submit_loader"},[_c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])]):_vm._e()])])])])])])])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }